.hide {
  display: none;
}
.mobile_bottom_nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 50px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: #fff;
}
.mobile_bottom_nav__item {
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mobile_bottom_nav__item--active {
  color: red;
}
.mobile_bottom_nav__item_content {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .hide {
    display: inline;
  }
}
